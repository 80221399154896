export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const LOAD_FAIL = 'LOAD_FAIL';
export const LOGOUT = 'LOGOUT';
export const VERIFICATION_SUCCESS = 'VERIFICATION_SUCCESS';
export const VERIFICATION_FAIL = 'VERIFICATION_FAIL';

/**
 * Enum for Modal Types in Home
 * @readonly
 * @enum {int}
 */
export const ModalTypes = Object.freeze({
	USER_ADD: 0,
	USER_EDIT: 1,
	USER_DELETE: 2,
	DEVICE_ADD: 3,
	DEVICE_EDIT: 4,
	DEVICE_DELETE: 5,
});
